.Spinner {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 5px solid var(--clr_primary);
    margin: auto;
    position: relative;
    z-index: 1;
}

.Spinner:before {
    content: "";
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-top: 5px solid var(--clr_secondary);
    border-right: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid transparent;
    position: absolute;
    z-index: 2;
    top: -5px;
    left: -5px;
    animation: Spin 1s infinite linear;
}

@media (min-width: 1200px) {
    .Spinner {
        width: 200px;
        height: 200px;
        border: 10px solid var(--clr_primary);
    }

    .Spinner:before {
        width: 200px;
        height: 200px;
        border-top: 10px solid var(--clr_secondary);
        border-right: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 10px solid transparent;
        top: -10px;
        left: -10px;
    }
}

@keyframes Spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.Spinner > .Logo {
    text-align: center;
    display: block;
    margin: 12% auto;
    height: 75%;
}
