:root {
    --clr_primary: #BADEE9FF;
    --clr_primary_dark: #00E1FF;
    --clr_secondary: #E9C5BA;
    --clr_secondary_dark: #FF9A7C;
    --clr_tertiary: #DEE9BA;
    --clr_quaternary: #DCBAE9;
    --clr_error: #aa1100;

    --toastify-color-progress-info: var(--clr_secondary) !important;
}

body {
    margin: 0;
    font-family: "Helvetica Neue", sans-serif;
    box-sizing: border-box;
    height: 100%;
    color: #000000;
}

#root {
    display: flex;
    flex-direction: column;
    height: 100vh;
    /* overflow-y: scroll; */
    scroll-behavior: smooth;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Wrapper {
    border: 2px solid var(--clr_primary);
    border-radius: 15px;
    box-sizing: border-box;
    padding: 1.2rem;
    box-shadow: 2px 3px rgba(0, 0, 0, 0.3);
    margin: 0.5rem;
}

hr {
    height: 1px;
    width: 95%;
    background-color: var(--clr_primary);
    border: none;
}

h1 {
    font-size: 36px;
    line-height: 48px;
}

h2 {
    font-size: 30px;
    line-height: 40px;
}

h3 {
    font-size: 24px;
    line-height: 34px;
}

h4 {
    font-size: 20px;
    line-height: 30px;
}

h5 {
    font-size: 16px;
    line-height: 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--clr_primary_dark);
}

.Capitalize {
    text-transform: capitalize;
}

.altText {
    color: var(--clr_secondary_dark);
}

.error {
    color: var(--clr_error);
}

.NoBulletPoint {
    list-style: None;
    padding-left: 0;
}

.SmallPadding-Top {
    padding-top: 0.25rem !important;
}

.SmallPadding-Right {
    padding-right: 0.25rem !important;
}

.SmallPadding-Bottom {
    padding-bottom: 0.25rem !important;
}

.SmallPadding-Left {
    padding-left: 0.25rem !important;
}

.MediumPadding-Top {
    padding-top: 0.5rem !important;
}

.MediumPadding-Right {
    padding-right: 0.5rem !important;
}

.MediumPadding-Bottom {
    padding-bottom: 0.5rem !important;
}

.MediumPadding-Left {
    padding-left: 0.5rem !important;
}

.LargePadding-Top {
    padding-top: 0.75rem !important;
}

.LargePadding-Right {
    padding-right: 0.75rem !important;
}

.LargePadding-Bottom {
    padding-bottom: 0.75rem !important;
}

.LargePadding-Left {
    padding-left: 0.75rem !important;
}

.SmallMargin-Top {
    margin-top: 0.25rem !important;
}

.SmallMargin-Right {
    margin-right: 0.25rem !important;
}

.SmallMargin-Bottom {
    margin-bottom: 0.25rem !important;
}

.SmallMargin-Left {
    margin-left: 0.25rem !important;
}

.MediumMargin-Top {
    margin-top: 0.5rem !important;
}

.MediumMargin-Right {
    margin-right: 0.5rem !important;
}

.MediumMargin-Bottom {
    margin-bottom: 0.5rem !important;
}

.MediumMargin-Left {
    margin-left: 0.5rem !important;
}

.LargeMargin-Top {
    margin-top: 0.75rem !important;
}

.LargeMargin-Right {
    margin-right: 0.75rem !important;
}

.LargeMargin-Bottom {
    margin-bottom: 0.75rem !important;
}

.LargeMargin-Left {
    margin-left: 0.75rem !important;
}

.W100 {
    width: 100%;
}

.W75 {
    width: 75%;
}

.W50 {
    width: 50%;
}

.W25 {
    width: 25%;
}

.H100 {
    height: 100%;
}

.H75 {
    height: 75%;
}

.H50 {
    height: 50%;
}

.H25 {
    height: 25%;
}

.Hide {
    display: none;
}

.Show {
    display: inherit;
}

table {
    border-collapse: collapse;
}

th, td, p {
    white-space: pre;
}

*,
::after,
::before {
    box-sizing: border-box;
}

::-webkit-scrollbar {
    width: 13px;
    border-radius: 0.25rem;
}

::-webkit-scrollbar-track {
    background: #f1f1f100;
    border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb {
    border: 1px solid rgb(177, 177, 177);
    border-radius: 0.25rem;
}

::-webkit-scrollbar-thumb:hover {
    background: rgb(177, 177, 177);
    border: 1px solid rgb(0, 0, 0);
}

.Scroll {
    overflow-y: scroll;
}

.Telephone {
    /* font-family: "Cormormant Infant", Helvetica, Arial, Verdana, san-serif; */
    font-weight: bold;
    /* padding-left: 15px; */
}

.Telephone a {
    text-decoration: none;
    color: #006bb7;
}

.TextCenter {
    text-align: center;
}

@keyframes bounce {
    0% {
        transform: scale3d(0.1, 0.1, 1);
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
    }
    55% {
        transform: scale3d(0.6, 0.6, 1);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0);
    }
    75% {
        transform: scale3d(0.95, 0.95, 1);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.9);
    }
    100% {
        transform: scale3d(1, 1, 1);
        box-shadow: 0 3px 20px rgba(0, 0, 0, 0.9);
    }
}

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}
