.Clock {
    font-family: sans-serif;
    display: flex;
    flex-direction: column;
}

.Wrapper {
    display: inline-block;
    margin: 0;
    text-align: center;
    position: relative;
    z-index: 1;
    top: 0;
    width: fit-content;
    
}
