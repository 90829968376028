.Content {
    flex: 1 0 auto;
    margin: 76px 1.2rem 15px 1.2rem;
}


.DisplayCenter {
    display: flex;
    margin-left: auto;
    margin-right: auto;
}

.LogoHolder {
    height: 10%;
    margin-bottom: 32px;
    text-align: center;
}

.Image {
    max-height: 100px;
}

.Logo {
    background-color: white;
    padding: 0.125rem;
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    max-height: 75px;
}

.LogoHolder img {
    height: 100%;
}

@media (min-width: 1200px) {
    .SmallImage img {
        height: 100%;
    }

    .LogoHolder {
        height: 90%;
        margin-left: 13rem;
        margin-bottom: 0;
    }
}
